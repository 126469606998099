export const HOME_ROUTE = '/';
export const OVERVIEW_ROUTE = '/overview';
export const BATCH_ROUTE = '/batch';
export const MAP_ROUTE = '/map';
export const TICKET_ROUTE = '/ticket';
export const ALERT_ROUTE = '/alert';
export const ERROR_ROUTE = '/errorpage';

export const MENU_NAME = {
    OVERVIEW: 'Overview',
    BATCHES: 'Batches',
    TICKET: 'Tickets',
    ALERT: 'Alerts',
    LOGOUT: 'Log out',
    MAP: 'Trailer locations'
};
