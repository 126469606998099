import { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import style from './LeftMenu.module.css';
import {
    OVERVIEW_ROUTE,
    BATCH_ROUTE,
    MENU_NAME,
    TICKET_ROUTE, ALERT_ROUTE, MAP_ROUTE,
} from "../../utils/RoutesConstants";
import { UserContext } from '../AuthorizedPage/UserProvider';
import { List, ListItem } from '@mui/material';
const LeftMenu = () => {
  const { signOut } = useContext(UserContext);
  const location = useLocation();

  return (
    <List>
      <ListItem
        key='1'
        disablePadding
        className={style.listContainer}
      >
        <div className={style.homeButton}>
        <Link
            to={OVERVIEW_ROUTE}
            className={
              location?.pathname === OVERVIEW_ROUTE ? style.firstBtn : style.btn
            }
            data-testid='Overview'
          >
            {MENU_NAME.OVERVIEW}
          </Link>
            <Link
                to={MAP_ROUTE}
                className={
                    location?.pathname === MAP_ROUTE ? style.firstBtn : style.btn
                }
                data-testid='map'
            >
                {MENU_NAME.MAP}
            </Link>
          <Link
            to={BATCH_ROUTE}
            className={
              location?.pathname === BATCH_ROUTE ? style.firstBtn : style.btn
            }
            data-testid='Batches'
          >
            {MENU_NAME.BATCHES}
          </Link>
          <Link
            to={TICKET_ROUTE}
            className={
              location?.pathname === TICKET_ROUTE ? style.firstBtn : style.btn
            }
            data-testid='ticket'
          >
            {MENU_NAME.TICKET}
          </Link>
            <Link
                to={ALERT_ROUTE}
                className={
                    location?.pathname === ALERT_ROUTE ? style.firstBtn : style.btn
                }
                data-testid='alert'
            >
                {MENU_NAME.ALERT}
            </Link>
          <Link
            to=''
            className={style.logoutbtn}
            onClick={signOut}
            data-testid='logout'
          >
            {MENU_NAME.LOGOUT}
          </Link>
        </div>
      </ListItem>
    </List>
  );
};

export default LeftMenu;
